import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.css";
import styles2 from "../../pages/proformaUser/addProforma.module.css";
import Select from "react-select";
import { Button } from "react-bootstrap";
import {
  disabledFields,
  headerFields,
  RegisterNameValues,
  textFields,
} from "./FieldsArray";
import { updateAssetRow } from "../../service/api";
import { showNotification } from "../../service/showNotification";

export const AssetRowPopup = ({
  rowData,
  setIsEditClicked,
  fetchAssetsData,
}) => {
  const { register, handleSubmit, reset } = useForm();

  const [selectedOptions, setSelectedOptions] = useState({
    label: "Active",
    value: "Active",
  });
  const [rowId, setRowId] = useState("");

  const statusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const onSubmit = async (values) => {
    try {
      console.log(values);
      const data = {
        ...values,
        status: selectedOptions.label,
      };

      await updateAssetRow(rowId, data);
      showNotification("success", "Values updated sucessfully");
      fetchAssetsData();
    } catch (error) {
      showNotification("error", "Something went wrong");
    } finally {
      setIsEditClicked(false);
    }
  };

  useEffect(() => {
    if (rowData && Object.keys(rowData)) {
      setRowId(rowData?._id);
      reset({
        assetId: rowData?._id,
        dealName: rowData?.dealName,
        assetOwner: rowData?.assetOwner,
        assetStage: rowData?.assetStage,
        financingOption: rowData?.financingOption,
        systemSize: rowData?.systemSize,
        ppaTerm: rowData?.ppaTerm,
        epcAmount: rowData?.epcAmount,
        deposit: rowData?.deposit,
        inceptionPurchasePrice: rowData?.inceptionPurchasePrice,
        inceptionEquityContribution: rowData?.inceptionEquityContribution,
        investorPurchasePrice: rowData?.investorPurchasePrice,
        investorEquityContribution: rowData?.investorEquityContribution,
        inceptionFinalMarkupPercentage: rowData?.inceptionFinalMarkupPercentage,
        inceptionFinalMarkup: rowData?.inceptionFinalMarkup,
        developerPayment: rowData?.developerPayment,
        developerMarkupCheck: rowData?.developerMarkupCheck,
        inverterWarranty: rowData?.inverterWarranty,
        investorPurchasePriceFinal: rowData?.investorPurchasePriceFinal,
        investorEquityContributionFinal:
          rowData?.investorEquityContributionFinal,
        adminFee: rowData?.adminFee,
        costPerKwh: rowData?.costPerKwh,
        trancheTitle: rowData?.trancheTitle,
        dealSource: rowData?.dealSource,
        assetType: rowData?.assetType,
        year1Production: rowData?.year1Production,
        productionGuarantee: rowData?.productionGuarantee,
        electricityOffsetPercentage: rowData?.electricityOffsetPercentage,
        fullName: rowData?.fullName,
        dealType: rowData?.dealType,
        installCompletedDate: rowData?.installCompletedDate,
        m1CertificateSignDate: rowData?.m1CertificateSignDate,
        energizedDate: rowData?.energizedDate,
        prePaidPercentageOfBillOfSale: rowData?.prePaidPercentageOfBillOfSale,
        m2CertificateSignedDate: rowData?.m2CertificateSignedDate,
        moduleManufacturerAVL: rowData?.moduleManufacturerAVL,
        inverterManufacturerAVL: rowData?.inverterManufacturerAVL,
        recordNumber: rowData?.recordNumber,
        assetStreetAddress: rowData?.assetStreetAddress,
        assetCity: rowData?.assetCity,
        assetState: rowData?.assetState,
        assetZipCode: rowData?.assetZipCode,
        initialTerm: rowData?.initialTerm,
        ppaRate: rowData?.ppaRate,
        estimatedExtendedWarrantyCost: rowData?.estimatedExtendedWarrantyCost,
        solarPrepaid: rowData?.solarPrepaid,
        batterySalesPrice: rowData?.batterySalesPrice,
        batteryPrepaid: rowData?.batteryPrepaid,
        billOfSaleAmount: rowData?.billOfSaleAmount,
        censusTractId: rowData?.censusTractId,
        costPerMonth: rowData?.costPerMonth,
        customerCounty: rowData?.customerCounty,
        dealOwner: rowData?.dealOwner,
        energyCommunityDescription: rowData?.energyCommunityDescription,
        energyCommunityTaxEligible: rowData?.energyCommunityTaxEligible,
        energyCommunityTitle: rowData?.dealOwner,
        energyCommunityType: rowData?.dealOwner,
        entityName: rowData?.entityName,
        entityType: rowData?.entityType,
        installerCertifiedToInstallBatteries:
          rowData?.installerCertifiedToInstallBatteries,
        inverterManufacturer: rowData?.inverterManufacturer,
        monitoringCommunication: rowData?.monitoringCommunication,
        panelManufacturer: rowData?.panelManufacturer,
        partialOrFullBackup: rowData?.partialOrFullBackup,
        propertyTrustName: rowData?.propertyTrustName,
        ptoLetterDate: rowData?.ptoLetterDate,
        residentialCustomerType: rowData?.residentialCustomerType,
        storageOnly: rowData?.storageOnly,
        annualDegradationPercentage: rowData?.annualDegradationPercentage,
        batteryKwSize: rowData?.batteryKwSize,
        sowAndPpaApprovalDate: rowData?.sowAndPpaApprovalDate,
      });
    }
  }, [rowData, reset]);

  return (
    <>
      <div
        className="overlay"
        // onClick={() => setIsEditClicked(false)}
      >
        <div>
          <div className="overlayContent" onClick={(e) => e.stopPropagation()}>
            <span className="closeBtn" onClick={() => setIsEditClicked(false)}>
              &times;
            </span>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.outerFormContainer}
            >
              <p className={styles2.heading}>Update Assets Details</p>
              <div className={styles.inputContainer}>
                <label className={styles2.labelStyle}>Status</label>
                <Select
                  options={statusOptions}
                  value={{
                    value: selectedOptions.value,
                    label: selectedOptions.label,
                  }}
                  onChange={(selected) => setSelectedOptions(selected)}
                />
              </div>

              {headerFields.map((field, index) => {
                if (field === "Status" || field === "Action") return "";
                const inputType = textFields.includes(field)
                  ? "text"
                  : "number";
                console.log(
                  field,
                  " ",
                  inputType,
                  " ",
                  RegisterNameValues[index]
                );
                const isDisabled = disabledFields.find(
                  (disabledField) => disabledField === field
                );

                return (
                  <div key={index} className={styles.inputContainer}>
                    <label className={styles2.labelStyle}>{field}</label>
                    <input
                      disabled={!!isDisabled}
                      {...register(`${RegisterNameValues[index]}`)}
                      className={styles2.inputBox}
                      step="any"
                      placeholder={`Enter ${field}`}
                      type={inputType}
                    />
                  </div>
                );
              })}
              <div className={styles.inputContainer}>
                <Button type="submit" style={{ marginLeft: "auto" }}>
                  Edit Asset
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
