import React from "react";
import { Table, Card, Pagination, Nav } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";
import '../assets/Styling/customstyle.css'

const FormatNumber = (number) => {
  return `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const FormatNumber1 = (number) => {
  return `${number.toString()}%`;
}

function ReportHistory({ data, totalDocs, setCurrentPage, currentPage, loading }) {
  const history = useHistory();
  const handleClick = (id) => {
    history.push({
      pathname: Routes.ReportHistoryEdit.path,
      search: `?id=${id}`,
    });
  };
  const totalTransactions = data?.length;
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalDocs / itemsPerPage);
  const showPaginationNumbers = totalPages > 10;

  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0 scrollable-table-wrapper">
          {(loading && data.length === 0) ? (
            <h1 className="mt-4 text-center w-full fs-4">Loading...</h1>
          ) : data.length < 0 ? (
            <h1 className="mt-4 text-center w-full fs-4">No Previous Records</h1>
          ) : (
            <div className="table-responsive">
              <Table hover className="user-table">
                <thead>
                  <tr>
                    <th className="border-bottom">Client Id</th>
                    <th className="border-bottom">Equity Contribution</th>
                    <th className="border-bottom">Select State</th>
                    <th className="border-bottom text-center">Gross Income</th>
                    <th className="border-bottom text-center">Estimated Tax Rates</th>
                    <th className="border-bottom text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="">
                          {item?.userId?.zohoCustomerId}
                        </td>
                        <td className="">
                          {FormatNumber(item?.inputId?.equalityContribution)}
                        </td>
                        <td className="">
                          {item?.inputId?.stateId?.state}
                        </td>
                        {
                          item.inputId.grossIncome ?
                            <td className="text-center">
                              {FormatNumber(item?.inputId?.grossIncome)}
                            </td> :
                            <td className="text-center">-</td>
                        }
                        <td className="text-center">
                          {FormatNumber1(item?.inputId?.estFederalTaxRate)}
                        </td>
                        <td className="text-center">
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => handleClick(item?.inputId?._id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                <Nav>
                  <Pagination className="mb-2 mb-lg-0">
                    <Pagination.Prev
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Pagination.Prev>
                    {!showPaginationNumbers &&
                      [...Array(totalPages)].map((_, page) => (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === currentPage}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      ))}
                    {showPaginationNumbers && (
                      <>
                        {currentPage > 4 && <Pagination.Ellipsis disabled />}
                        {[...Array(totalPages)].map((_, page) => {
                          if (
                            (page === 0 && currentPage < 5) ||
                            (page === totalPages - 1 &&
                              currentPage > totalPages - 5) ||
                            (page > currentPage - 3 && page < currentPage + 2)
                          ) {
                            return (
                              <Pagination.Item
                                key={page + 1}
                                active={page + 1 === currentPage}
                                onClick={() => setCurrentPage(page + 1)}
                              >
                                {page + 1}
                              </Pagination.Item>
                            );
                          }
                          return null;
                        })}
                        {currentPage < totalPages - 3 && (
                          <Pagination.Ellipsis disabled />
                        )}
                        {currentPage !== totalPages ? (
                          <Pagination.Item
                            onClick={() => setCurrentPage(totalPages)}
                          >
                            {totalPages}
                          </Pagination.Item>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {
                      totalDocs ?
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(
                                prev + 1,
                                Math.ceil(totalDocs / itemsPerPage)
                              )
                            )
                          }
                          disabled={currentPage === totalPages}
                        >Next
                        </Pagination.Next>
                        :
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(
                                prev + 1,
                                Math.ceil(totalDocs / itemsPerPage)
                              )
                            )
                          }
                        >
                          Next
                        </Pagination.Next>
                    }
                  </Pagination>
                </Nav>

                <small className="fw-bold">
                  Showing <b>{totalTransactions}</b> out of <b>{totalDocs}</b>{" "}
                  entries
                </small>
              </Card.Footer>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default ReportHistory;