import React, { useEffect, useState } from "react";
import "../assets/Styling/customstyle.css";
import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import "../assets/Styling/purljourney.css";
import { getPurlFlow, updatePurlFlow } from "../service/api";

export const PURLJourney = () => {
  const [toggleSwitch, setToggleSwitch] = useState({
    video: true,
    presentation: true,
    benefitCalculator: true,
    dashboard: true,
    interactivePresentation: true,
    solarTracker: true,
  });

  const getFlow = async () => {
    try {
      const response = await getPurlFlow();
      const data = response.data.data[0];
      console.log(data, "datadta");
      const newObj = {
        video: data.video,
        presentation: data.presentation,
        benefitCalculator: data.benefitCalculator,
        dashboard: data.dashboard,
        interactivePresentation: data.interactivePresentation,
        solarTracker: data.solarTracker,
      };
      setToggleSwitch(newObj);
    } catch (err) {
      console.log(err);
    }
  };

  const onButtonToggle = async (field) => {
    if (field === "dashboard") {
      alert("Do not turn it on as beta dashboard is currently not stable");
    }
    const newObj = { ...toggleSwitch, [field]: !toggleSwitch[field] };
    setToggleSwitch(newObj);
    await updatePurlFlow({ field: field });
    getFlow();
  };
  useEffect(() => {
    getFlow();
  }, []);

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent ",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>PURL Journey</Breadcrumb.Item>
          </Breadcrumb>
          <h4>PURL Journey</h4>
        </div>
      </div>

      <div className="scrollable-table-container2 mx-3">
        <div className="toggleContainer">
          <div className="singleToggle">
            <label className="labelText">Show / Hide Video</label>
            <div class="custom-switch">
              <input
                type="checkbox"
                checked={toggleSwitch.video}
                id="toggle-switch1"
                onClick={() => onButtonToggle("video")}
              />
              <label for="toggle-switch1" class="switch-label">
                <span class="switch-button"></span>
                <span class="switch-on">ON</span>
                <span class="switch-off">OFF</span>
              </label>
            </div>
          </div>
          <div className="singleToggle">
            <label className="labelText">Show / Hide Presentation</label>
            <div class="custom-switch">
              <input
                type="checkbox"
                checked={toggleSwitch.presentation}
                id="toggle-switch2"
                onClick={() => onButtonToggle("presentation")}
              />
              <label for="toggle-switch2" class="switch-label">
                <span class="switch-button"></span>
                <span class="switch-on">ON</span>
                <span class="switch-off">OFF</span>
              </label>
            </div>
          </div>
          <div className="singleToggle">
            <label className="labelText">
              Show / Hide Interactive Presentation
            </label>
            <div class="custom-switch">
              <input
                type="checkbox"
                checked={toggleSwitch.interactivePresentation}
                id="toggle-switch5"
                onClick={() => onButtonToggle("interactivePresentation")}
              />
              <label for="toggle-switch5" class="switch-label">
                <span class="switch-button"></span>
                <span class="switch-on">ON</span>
                <span class="switch-off">OFF</span>
              </label>
            </div>
          </div>
          <div className="singleToggle">
            <label className="labelText">Show / Hide Benefit Calculator</label>
            <div class="custom-switch">
              <input
                type="checkbox"
                checked={toggleSwitch.benefitCalculator}
                id="toggle-switch3"
                onClick={() => onButtonToggle("benefitCalculator")}
              />
              <label for="toggle-switch3" class="switch-label">
                <span class="switch-button"></span>
                <span class="switch-on">ON</span>
                <span class="switch-off">OFF</span>
              </label>
            </div>
          </div>
          <div className="singleToggle">
            <label className="labelText">Show Dashboard Beta</label>
            <div class="custom-switch">
              <input
                type="checkbox"
                checked={toggleSwitch.dashboard}
                id="toggle-switch4"
                onClick={() => onButtonToggle("dashboard")}
              />
              <label for="toggle-switch4" class="switch-label">
                <span class="switch-button"></span>
                <span class="switch-on">ON</span>
                <span class="switch-off">OFF</span>
              </label>
            </div>
          </div>
          <div className="singleToggle">
            <label className="labelText">Show Solar Widget</label>
            <div class="custom-switch">
              <input
                type="checkbox"
                checked={toggleSwitch.solarTracker}
                id="toggle-switch6"
                onClick={() => onButtonToggle("solarTracker")}
              />
              <label for="toggle-switch6" class="switch-label">
                <span class="switch-button"></span>
                <span class="switch-on">ON</span>
                <span class="switch-off">OFF</span>
              </label>
            </div>
          </div>
          <div className="noteText">
            ON stands for particular field is Visible to User
          </div>
        </div>
      </div>
    </>
  );
};
