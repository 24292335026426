import axios from "axios";

export const baseUrl = `https://api.inceptionfinancial.app/api/admin`;
// const baseUrl = `http://localhost:5000/api/admin`;

export const http = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "multipart/form-data",
    // "Cache-Control": "no-cache",
    // 'Authorization': `Bearer ${token}`,
  },
});

http.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 400) {
      console.log("Error : ", error?.response?.data?.message);
    }
    return Promise.reject(error);
  }
);
