import React, { useCallback, useEffect, useState } from "react";
import { Card, Nav, Pagination, Table } from "react-bootstrap";
import { TableSkeleton } from "../../skeleton/TableSkeleton";
import { deleteAssets, getAllAssetsData } from "../../service/api";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { showNotification } from "../../service/showNotification";
import { AssetRowPopup } from "./AssetRowPopup";
import { headerFields } from "./FieldsArray";

const AssetsTable = ({ isNewUploaded }) => {
  const [loading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(10);
  const [totalPages, setTotalPages] = useState(10);
  const [rowData, setRowData] = useState({});
  const [isEditClicked, setIsEditClicked] = useState(false);
  const itemsPerPage = 10;
  const showPaginationNumbers = totalPages > 10;

  const deleteStyles = {
    marginRight: "10px",
    color: "red",
    fontSize: "1.2rem",
  };
  const EditStyles = {
    color: "green",
    fontSize: "1.2rem",
  };

  const fetchAssetsData = useCallback(async () => {
    try {
      const response = await getAllAssetsData(currentPage, itemsPerPage);
      setAssets(response.data.assets);
      const totalPages = Math.ceil(response.data.totalDocs / itemsPerPage);
      setTotalDocs(response.data.totalDocs);
      setTotalPages(totalPages);
      console.log("hi");
    } catch (error) {
      console.log(error);
    }
  }, [currentPage]);

  const handleDelete = async (id) => {
    try {
      await deleteAssets(id);
      showNotification("success", "Row deleted successfully");
      fetchAssetsData();
    } catch (error) {
      showNotification("Something went wrong");
    }
  };
  const handleEdit = async (item) => {
    setRowData(item);
    setIsEditClicked(true);
  };

  useEffect(() => {
    fetchAssetsData();
  }, [currentPage, isNewUploaded, fetchAssetsData]);

  return (
    <>
      {loading ? (
        <TableSkeleton />
      ) : (
        <div className="scrollable-table-container2 mx-3">
          <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
          >
            <Card.Body className="pt-0">
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    {headerFields.map((header, index) => (
                      <th>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {assets?.map((item) => {
                    return (
                      <tr key={item._id}>
                        <th>{item?.status || "-"}</th>
                        <th>{item?._id || "-"}</th>
                        <th>{item?.dealName || "-"}</th>
                        <th>{item?.assetOwner || "-"}</th>
                        <th>{item?.assetStage || "-"}</th>
                        <th>{item?.financingOption || "-"}</th>
                        <th>{item?.systemSize || "-"}</th>
                        <th>{item?.ppaTerm || "-"}</th>
                        <th>{item?.epcAmount || "-"}</th>
                        <th>{item?.deposit || "-"}</th>
                        <th>{item?.inceptionPurchasePrice || "-"}</th>
                        <th>{item?.inceptionEquityContribution || "-"}</th>
                        <th>{item?.investorPurchasePrice || "-"}</th>
                        <th>{item?.investorEquityContribution || "-"}</th>
                        <th>{item?.inceptionFinalMarkupPercentage || "-"}</th>
                        <th>{item?.inceptionFinalMarkup || "-"}</th>
                        <th>{item?.developerPayment || "-"}</th>
                        <th>{item?.developerMarkupCheck || "-"}</th>
                        <th>{item?.inverterWarranty || "-"}</th>
                        <th>{item?.investorPurchasePriceFinal || "-"}</th>
                        <th>{item?.investorEquityContributionFinal || "-"}</th>
                        <th>{item?.adminFee || "-"}</th>
                        <th>{item?.costPerKwh || "-"}</th>
                        <th>{item?.trancheTitle || "-"}</th>
                        <th>{item?.dealSource || "-"}</th>
                        <th>{item?.assetType || "-"}</th>
                        <th>{item?.year1Production || "-"}</th>
                        <th>{item?.productionGuarantee || "-"}</th>
                        <th>{item?.electricityOffsetPercentage || "-"}</th>
                        <th>{item?.fullName || "-"}</th>
                        <th>{item?.dealType || "-"}</th>
                        <th>{item?.installCompletedDate || "-"}</th>
                        <th>{item?.m1CertificateSignDate || "-"}</th>
                        <th>{item?.energizedDate || "-"}</th>
                        <th>{item?.prePaidPercentageOfBillOfSale || "-"}</th>
                        <th>{item?.m2CertificateSignedDate || "-"}</th>
                        <th>{item?.moduleManufacturerAVL || "-"}</th>
                        <th>{item?.inverterManufacturerAVL || "-"}</th>
                        <th>{item?.recordNumber || "-"}</th>
                        <th>{item?.assetStreetAddress || "-"}</th>
                        <th>{item?.assetCity || "-"}</th>
                        <th>{item?.assetState || "-"}</th>
                        <th>{item?.assetZipCode || "-"}</th>
                        <th>{item?.initialTerm || "-"}</th>
                        <th>{item?.ppaRate || "-"}</th>
                        <th>{item?.estimatedExtendedWarrantyCost || "-"}</th>
                        <th>{item?.solarPrepaid || "-"}</th>
                        <th>{item?.batterySalesPrice || "-"}</th>
                        <th>{item?.batteryPrepaid || "-"}</th>
                        <th>{item?.billOfSaleAmount || "-"}</th>
                        <th>{item?.censusTractId || "-"}</th>
                        <th>{item?.costPerMonth || "-"}</th>
                        <th>{item?.customerCounty || "-"}</th>
                        <th>{item?.dealOwner || "-"}</th>
                        <th>{item?.energyCommunityDescription || "-"}</th>
                        <th>{item?.energyCommunityTaxEligible || "-"}</th>
                        <th>{item?.energyCommunityTitle || "-"}</th>
                        <th>{item?.energyCommunityType || "-"}</th>
                        <th>{item?.entityName || "-"}</th>
                        <th>{item?.entityType || "-"}</th>
                        <th>
                          {item?.installerCertifiedToInstallBatteries || "-"}
                        </th>
                        <th>{item?.inverterManufacturer || "-"}</th>
                        <th>{item?.monitoringCommunication || "-"}</th>
                        <th>{item?.panelManufacturer || "-"}</th>
                        <th>{item?.partialOrFullBackup || "-"}</th>
                        <th>{item?.propertyTrustName || "-"}</th>
                        <th>{item?.ptoLetterDate || "-"}</th>
                        <th>{item?.residentialCustomerType || "-"}</th>
                        <th>{item?.storageOnly || "-"}</th>
                        <th>{item?.annualDegradationPercentage || "-"}</th>
                        <th>{item?.batteryKwSize || "-"}</th>
                        <th>{item?.sowAndPpaApprovalDate || "-"}</th>
                        <th style={{ width: "3rem" }}>
                          <MdDelete
                            onClick={() => handleDelete(item?._id)}
                            style={deleteStyles}
                          />
                          <FaEdit
                            onClick={() => handleEdit(item)}
                            style={EditStyles}
                          />
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-lg-flex  justify-content-between">
                <Nav>
                  <Pagination className="mb-2 mb-lg-0">
                    <Pagination.Prev
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Pagination.Prev>
                    {showPaginationNumbers && (
                      <>
                        {currentPage > 4 && (
                          <>
                            <Pagination.Item onClick={() => setCurrentPage(1)}>
                              1
                            </Pagination.Item>
                            <Pagination.Ellipsis disabled />
                          </>
                        )}
                        {[...Array(totalPages)].map((_, page) => {
                          if (
                            (page === 0 && currentPage < 5) ||
                            (page === totalPages - 1 &&
                              currentPage > totalPages - 5) ||
                            (page > currentPage - 3 && page < currentPage + 2)
                          ) {
                            return (
                              <Pagination.Item
                                key={page + 1}
                                active={page + 1 === currentPage}
                                onClick={() => setCurrentPage(page + 1)}
                              >
                                {page + 1}
                              </Pagination.Item>
                            );
                          }
                          return null;
                        })}
                        {currentPage < totalPages - 3 && (
                          <>
                            <Pagination.Ellipsis disabled />
                            <Pagination.Item
                              onClick={() => setCurrentPage(totalPages)}
                            >
                              {totalPages}
                            </Pagination.Item>
                          </>
                        )}
                      </>
                    )}
                    <Pagination.Next
                      onClick={() =>
                        setCurrentPage((prev) =>
                          Math.min(
                            prev + 1,
                            Math.ceil(totalDocs / itemsPerPage)
                          )
                        )
                      }
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Pagination.Next>
                  </Pagination>
                </Nav>
              </Card.Footer>
            </Card.Body>
          </Card>
        </div>
      )}
      {isEditClicked && (
        <AssetRowPopup
          rowData={rowData}
          setIsEditClicked={setIsEditClicked}
          fetchAssetsData={fetchAssetsData}
        />
      )}
    </>
  );
};

export default AssetsTable;
