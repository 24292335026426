import React, { useEffect, useState } from 'react'
import { updateReply } from '../service/api'
import { useForm } from 'react-hook-form';
import { replySchema } from '../yup';
import { yupResolver } from "@hookform/resolvers/yup";

export const EntityReplyPopup = ({ setReplyPopup, data, setReload }) => {

    const {
        register,
        handleSubmit,
        setValue,
    } = useForm({
        resolver: yupResolver(replySchema),
    });

    const [isSuccess, setIsSuccess] = useState(false)

    const onClose = () => {
        setReplyPopup(false)
    }

    const onSubmit = async (value) => {
        try {
            await updateReply(data._id, value)
            setIsSuccess(true)
            setReload(prev => !prev)
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (data && data.message) {
            setValue('reply', data.message, { shouldValidate: true });
        }
    }, [data, setValue]);

    return (
        <>
            <div
                className='overlay'
                onClick={() => onClose()}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='overlayContent' onClick={e => e.stopPropagation()}>
                        <span className='closeBtn' onClick={() => onClose()}>
                            &times;
                        </span>
                        {isSuccess ?
                            <div>Message sent successfully</div>
                            :
                            // <div className='titleBox'>
                            //     <h5>Message</h5>
                            //     <div className='labels'>
                            //         {data.message ?? '-'}
                            //     </div>
                            //     <h5>Reply</h5>
                            //     <textarea rows="4" cols="15"
                            //         style={{ border: '1px solid black' }}
                            //         {...register('reply')}
                            //     >
                            //     </textarea>
                            //     <div
                            //         style={{
                            //             textAlign: 'right', margin: '1rem 0 0 0'
                            //         }}
                            //     >
                            //         <button 
                            //             style={{
                            //                 color: '#ccccc',
                            //                 border: '1px solid #ccccc',
                            //                 width: '100px',
                            //             }}
                            //         >
                            //             Submit
                            //         </button>
                            //     </div>
                            // </div>
                            <div className='titleBox'>
                                <h5>Comment</h5>
                                <div>
                                    <textarea rows="4" cols="70"
                                        style={{
                                            border: '1px solid black',
                                            padding: '.5rem',
                                            width: '100%'
                                        }}
                                        {...register('reply')}
                                    >
                                    </textarea>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'right', margin: '1rem 0 0 0'
                                    }}
                                >
                                    <button
                                        style={{
                                            color: '#ccccc',
                                            border: '1px solid #ccccc',
                                            width: '100px',
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </form>

            </div>
        </>
    )
}