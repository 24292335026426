import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import {
  ConstantTable,
  MacrsTable,
  StateMatrixTable,
} from "../../components/Tables";
import {
  fetchFormData,
  fetchStatesData,
  DownloadStateMatrix,
  updateUploadedStates,
} from "../../service/api";
import Layout from "../../components/Layout";
import "../../assets/Styling/customstyle.css";
import { TableSkeleton } from "../../skeleton/TableSkeleton";
import { Button } from "@themesberg/react-bootstrap";
import { showNotification } from "../../service/showNotification";
import ProformaBackend from "../../components/ProformaBackend";
export default () => {
  const [states, setStates] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState(false);
  const [modelData, setModelData] = useState({});
  const fileInputRef = useRef();

  const modelOptions = [
    { value: "Baseline Model", label: "Baseline Model" },
    { value: "Primary Model", label: "Primary Model" },
    { value: "Secondary Model", label: "Secondary Model" },
    { value: "Secondary Sales Model", label: "Secondary Sales Model" },
    { value: "Primary Sales Model", label: "Primary Sales Model" },
    { value: "Other", label: "Other" },
  ];

  const [selectedModel, setSelectedModel] = useState(modelOptions[0]);

  const getFormData = async (model = "Primary Model") => {
    try {
      setLoading(true);
      const resp = await fetchFormData(model);
      if (resp?.data.success) {
        const filteredData = resp.data.data;
        setModelData(filteredData);
      }
    } catch (err) {
      console.log("err");
    } finally {
      setLoading(false);
    }
  };
  const getStatesData = async () => {
    try {
      const resp = await fetchStatesData();
      if (resp?.data.success) {
        setStates(resp.data.data);
      }
    } catch (err) {
      console.log("err");
    }
  };

  useEffect(() => {
    getFormData(selectedModel.value);
    getStatesData();
  }, [selectedModel]);

  const handleModelChange = (selectedOption) => {
    setSelectedModel(selectedOption);
    getFormData(selectedOption.value);
  };
  const onUpdate = () => {
    getFormData(selectedModel.value);
    getStatesData();
  };

  const handleDownload = async () => {
    try {
      const response = await DownloadStateMatrix();
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "State Matrix.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error", error.message);
    }
  };
  const handleUpload = async (e) => {
    try {
      e.preventDefault();
      const file = e.target?.files?.[0];

      const formData = new FormData();
      formData.append("file", file);

      const data = await updateUploadedStates(formData);
      console.log(data);
      if (data) {
        showNotification("success", "States uploaded successfully");
      }
    } catch (error) {
      console.log("error", error.message);
      showNotification("error", "File not uploaded");
    }
  };

  return (
    <Layout>
      <>
        <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
          <div className="d-block mb-4 mb-xl-0">
            <Breadcrumb
              className="d-none d-md-inline-block"
              listProps={{
                className: "breadcrumb-dark breadcrumb-transparent",
              }}
            >
              <Breadcrumb.Item>
                <FontAwesomeIcon icon={faHome} />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Tables</Breadcrumb.Item>
            </Breadcrumb>
            <h4>Proforma Backend</h4>
          </div>

          <div className=" search-container ">
            <Select
              // className="model-select select-dropdown"
              className="model-select select-dropdown "
              value={selectedModel}
              onChange={handleModelChange}
              options={modelOptions}
              placeholder="Select a Model"
            />
          </div>
        </div>

        {loading ? (
          <TableSkeleton />
        ) : (
          selectedModel.label === "Baseline Model" ?
            <>
              <h4>Proforma Backend Values</h4>
              <ProformaBackend />
              <div className="d-flex justify-content-between">
                <h4 className="mt-2">State Matrix</h4>
                <div>
                  <Button
                    className="mx-2 mb-2"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload
                  </Button>
                  <input
                    onChange={handleUpload}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                  />
                  <Button className="mx-2 mb-2" onClick={handleDownload}>
                    Download
                  </Button>
                </div>
              </div>
              <StateMatrixTable
                states={states}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                onUpdate={onUpdate}
              />
            </>
            :
            <>
              <ConstantTable
                data={modelData || {}}
                selectedOption={selectedOption}
                onUpdate={onUpdate}
                selectedModel={selectedModel}
                modelId={modelData._id}
              />
              <h4>MACRS Depreciation Rate</h4>
              <MacrsTable selectedOption={selectedOption} onUpdate={onUpdate} />
              <div className="d-flex justify-content-between">
                <h4 className="mt-2">State Matrix</h4>
                <div>
                  <Button
                    className="mx-2 mb-2"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload
                  </Button>
                  <input
                    onChange={handleUpload}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    hidden
                  />
                  <Button className="mx-2 mb-2" onClick={handleDownload}>
                    Download
                  </Button>
                </div>
              </div>
              <StateMatrixTable
                states={states}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                onUpdate={onUpdate}
              />
            </>
        )}
      </>
    </Layout>
  );
};
