import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateUserInput, UpdateReport } from "../service/api";
import { Routes } from "../routes";
import { useHistory } from "react-router-dom";
import Preloader from "./Preloader";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
  Form,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import "../assets/Styling/customstyle.css";
import Select from "react-select";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MacrsSchema, stateSchema, tableSchema } from "../yup";
import { sendFormData, sendStatesData } from "../service/api";
import { showNotification } from "../service/showNotification";

export const TransactionsTable = ({
  data = [],
  totalDocs,
  setCurrentPage,
  currPage,
}) => {
  const totalTransactions = data?.length;
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalDocs / itemsPerPage);
  const showPaginationNumbers = totalPages > 10;
  const TableRow = (props) => {
    const { userId, createdAt, summary, index, isEstimated, _id, zohoCustomerId, firstName, lastName } =
      props;
    if (summary?.length > 1) {
      var {
        equalityContribution,
        netBenefit,
        operatingExp,
        totalBenefit,
        netBenefitPercentage,
      } = summary[0];
    }

    const handleDownload = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://api.inceptionfinancial.app/api/admin/download-report/${props._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response || !response.body) {
          return;
        }
        const reader = response.body.getReader();
        const chunks = [];
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          chunks.push(value);
        }
        const blob = new Blob(chunks);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "filename.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log("error", error);
      }
    };
    return (
      <>
        {userId && (
          <tr>
            <td>
              <Card.Link
                as={Link}
                to={Routes.Invoice.path}
                className="fw-normal"
              >
                {(currPage - 1) * 10 + index + 1}
              </Card.Link>
            </td>
            <td>
              <span className="fw-normal">{_id}</span>
            </td>
            <td>
              <span className="fw-normal">{zohoCustomerId}</span>
            </td>
            <td>
              <span className="fw-normal">
                {firstName} {lastName}
              </span>
            </td>
            <td>
              <span className="fw-normal">$ {equalityContribution}</span>
            </td>
            <td>
              <span className="fw-normal">$ {operatingExp}</span>
            </td>
            <td>
              <span className="fw-normal">$ {totalBenefit}</span>
            </td>
            <td>
              <span className="fw-normal">$ {netBenefit}</span>
            </td>
            <td>
              <span className="fw-normal">{netBenefitPercentage}%</span>
            </td>
            <td>
              <span className="fw-normal">
                {
                  isEstimated ?
                    <td>
                      <p className="status-btn3">Estimated</p>
                    </td> :
                    <td>
                      <p className="status-btn">Actual</p>
                    </td>
                }
              </span>
            </td>
            <td className="flex align-center jsutify-center flex-col ">
              <span className="fw-normal">
                {moment(createdAt).format("lll")}
              </span>
            </td>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  as={Button}
                  split
                  variant="link"
                  className=" text-dark m-0 p-0 "
                >
                  <span className="icon icon-sm ">
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      className="icon-dark "
                    />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="" style={{ padding: "0px" }}>
                  <Dropdown.Item
                    style={{ marginRight: "0px" }}
                    className="text-black "
                    onClick={handleDownload}
                  >
                    Download
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )}
      </>
    );
  };
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">S.No</th>
              <th className="border-bottom">ID</th>
              <th className="border-bottom">ZOHO CUSTOMER ID</th>
              <th className="border-bottom">User</th>
              <th className="border-bottom">Equality Contribution</th>
              <th className="border-bottom">Operating Exp</th>
              <th className="border-bottom">Total Benefit</th>
              <th className="border-bottom">Net Benefit</th>
              <th className="border-bottom">Net Benefit %</th>
              <th className="border-bottom">Estimated / Actual</th>
              <th className="border-bottom">Created On</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length >= 1 &&
              data?.map((t, index) => (
                <TableRow
                  key={`transaction-${index}`}
                  {...t}
                  index={index}
                  isEstimated={t.isEstimated}
                />
              ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex  justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currPage === 1}
              >
                Previous
              </Pagination.Prev>
              {showPaginationNumbers && (
                <>
                  {currPage > 4 && (
                    <>
                      <Pagination.Item onClick={() => setCurrentPage(1)}>1</Pagination.Item>
                      <Pagination.Ellipsis disabled />
                    </>
                  )}
                  {[...Array(totalPages)].map((_, page) => {
                    if (
                      (page === 0 && currPage < 5) ||
                      (page === totalPages - 1 && currPage > totalPages - 5) ||
                      (page > currPage - 3 && page < currPage + 2)
                    ) {
                      return (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === currPage}
                          onClick={() => setCurrentPage(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      );
                    }
                    return null;
                  })}
                  {currPage < totalPages - 3 && (
                    <>
                      <Pagination.Ellipsis disabled />
                      <Pagination.Item onClick={() => setCurrentPage(totalPages)}>
                        {totalPages}
                      </Pagination.Item>
                    </>
                  )}
                </>
              )}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((prev) =>
                    Math.min(prev + 1, Math.ceil(totalDocs / itemsPerPage))
                  )
                }
                disabled={currPage === totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>{totalDocs}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const SubAdminTable = ({
  data,
  setShowDeleteModal,
  setcurrPage,
  currPage,
  totalDocs,
  setId,
  setShowUpdateModal,
}) => {
  const totalTransactions = data.length;
  const handleDelete = async (id) => {
    setShowDeleteModal(true);
    setId(id);
  };
  const handleUpdate = (id) => {
    setShowUpdateModal(true);
    setId(id);
  };
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalDocs / itemsPerPage);

  // Determine whether to display pagination for the page numbers
  const showPaginationNumbers = totalPages > 10;

  const TableRow = (props) => {
    const { _id, index, name, email, btnText, createdAt, isActive } = props;
    const statusVariant =
      btnText === "Pending"
        ? "success"
        : btnText === "Due"
          ? "warning"
          : btnText === "Canceled"
            ? "danger"
            : "primary";

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {(currPage - 1) * 25 + index + 1}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{name}</span>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
        <td>
          <span className="fw-normal">{moment(createdAt).format("lll")}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>
            {isActive ? `Active` : `In-Active`}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleUpdate(_id)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleDelete(_id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Email</th>
              <th className="border-bottom">Created On</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <TableRow index={index} {...item} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev
                onClick={() => setcurrPage((prev) => Math.max(prev - 1, 1))}
                disabled={currPage === 1}
              >
                Previous
              </Pagination.Prev>
              {!showPaginationNumbers &&
                [...Array(totalPages)].map((_, page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currPage}
                    onClick={() => setcurrPage(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
              {showPaginationNumbers && (
                <>
                  {currPage > 4 && <Pagination.Ellipsis disabled />}
                  {[...Array(totalPages)].map((_, page) => {
                    if (
                      (page === 0 && currPage < 5) ||
                      (page === totalPages - 1 && currPage > totalPages - 5) ||
                      (page > currPage - 3 && page < currPage + 2)
                    ) {
                      return (
                        <Pagination.Item
                          key={page + 1}
                          active={page + 1 === currPage}
                          onClick={() => setcurrPage(page + 1)}
                        >
                          {page + 1}
                        </Pagination.Item>
                      );
                    }
                    return null;
                  })}
                  {currPage < totalPages - 3 && (
                    <Pagination.Ellipsis disabled />
                  )}
                  {totalPages > 1 && totalPages !== currPage && (
                    <Pagination.Item onClick={() => setcurrPage(totalPages)}>
                      {totalPages}
                    </Pagination.Item>
                  )}
                </>
              )}
              <Pagination.Next
                onClick={() =>
                  setcurrPage((prev) =>
                    Math.min(prev + 1, Math.ceil(totalDocs / itemsPerPage))
                  )
                }
                disabled={currPage === totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>

          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>{totalDocs}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const ConstantTable = ({
  data = {},
  onUpdate,
  isUserInput,
  userInputId,
  selectedModel,
  modelId,
}) => {
  const inlineStyle = { marginTop: "10px" };
  const [error, setError] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(tableSchema),
    mode: "onChange",
    defaultValues: {
      ...Object.fromEntries(Object.keys(data).map((key) => [key, 0])),
    },
  });
  const GernateReport = async () => {
    setIsLoading(true);
    const response = await UpdateReport({
      userInputId: data._id,
      userId: data.userId,
    });
    if (response) {
      history.push({
        pathname: Routes.ReportResult.path,
        state: response.data.data,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const handleClick = () => {
    GernateReport();
  };

  const renderLoadingButton = () => (
    <Button type="Submit" isLoading={isLoading}>
      <Preloader show={isLoading} />
    </Button>
  );

  const renderUserInputButton = () => (
    <Button type="Submit" style={{ marginLeft: "15px" }} onClick={handleClick}>
      Next
    </Button>
  );

  const onSubmit = async (values) => {
    if (isUserInput === true) {
      const response = await updateUserInput(userInputId, values);
      if (!response.success) setError(response.error);
      if (response) {
        showNotification("success", response?.data?.message);
      }
    } else {
      const formDataWithModel = { ...values, type: selectedModel.value };
      const resp = await sendFormData(formDataWithModel, modelId);
      // const resp = await sendFormData(values);
      if (!resp.success) {
        setError(resp.error);
        onUpdate();
      }
      if (resp) {
        showNotification("success", resp?.data?.message);
      }
    }
  };

  return (
    <Card border="light" className="flex-column shadow-sm mb-4">
      <Card.Body className="flex-column flex-sm-row">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="adminFeeStartingYear"
            >
              <Form.Label>Admin Fee Starting Year :</Form.Label>
              <Form.Control
                {...register("adminFeeStartingYear")}
                placeholder="Enter Admin Fee Starting Year"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="assetManagement"
            >
              <Form.Label>Asset Management :</Form.Label>
              <Form.Control
                {...register("assetManagement")}
                required
                placeholder="Enter Admin Fee Starting Year"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="assetManagementPercent"
            >
              <Form.Label>Asset Management % :</Form.Label>
              <Form.Control
                {...register("assetManagementPercent")}
                required
                placeholder="Fixed with regular updated based on market place of assets"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="assumedPercentageInveterWarranty"
            >
              <Form.Label>Assumed Percentage Inveter Warranty :</Form.Label>
              <Form.Control
                {...register("assumedPercentageInveterWarranty")}
                required
                placeholder="Enter Assumed Percentage Inveter Warranty"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="costPerExtendedInveterWarranty"
            >
              <Form.Label>Cost Per Extended Inveter Warranty :</Form.Label>
              <Form.Control
                {...register("costPerExtendedInveterWarranty")}
                required
                placeholder="Enter Cost Per Extended Inveter Warranty"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="developerDepositPayment1"
            >
              <Form.Label>Developer Deposit Payment 1 % :</Form.Label>
              <Form.Control
                {...register("developerDepositPayment1")}
                required
                placeholder="Enter Developer Deposit Payment1"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="developerDepositPayment2"
            >
              <Form.Label>Developer Deposit Payment 2 % :</Form.Label>
              <Form.Control
                {...register("developerDepositPayment2")}
                required
                placeholder="Enter Developer Deposit Payment2"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="developerDepositPayment3"
            >
              <Form.Label>Developer Deposit Payment 3 % :</Form.Label>
              <Form.Control
                {...register("developerDepositPayment3")}
                required
                placeholder="Enter Developer Deposit Payment3"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="developerDevFee"
            >
              <Form.Label>Developer Dev Fee % :</Form.Label>
              <Form.Control
                {...register("developerDevFee")}
                required
                placeholder="Enter Developer Dev Fee"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="inceptionDevFeePercent"
            >
              <Form.Label>Inception Dev Fee % :</Form.Label>
              <Form.Control
                {...register("inceptionDevFeePercent")}
                required
                placeholder="Enter Inception Dev Fee"
              />
            </Form.Group>

            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="federalBonusDepreciation"
            >
              <Form.Label>Federal Bonus Depreciation % :</Form.Label>
              <Form.Control
                {...register("federalBonusDepreciation")}
                required
                placeholder="Enter Federal Bonus Depreciation"
              />
            </Form.Group>
            {/* <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="federalBonusDepreciationPercent"
            >
              <Form.Label>Federal Bonus Depreciation Percent :</Form.Label>
              <Form.Control
                {...register("federalBonusDepreciationPercent")}
                required
                placeholder="Enter Federal Bonus Depreciation Percent"
              />
            </Form.Group> */}
            <Form.Group style={inlineStyle} as={Col} sm="4" id="itcPercent">
              <Form.Label>ITC % :</Form.Label>
              <Form.Control
                {...register("itcPercent")}
                required
                placeholder="Enter ITC Percent"
              />
            </Form.Group>
            {/* <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="marginalFedTaxRate"
            >
              <Form.Label>Marginal Fed Tax Rate :</Form.Label>
              <Form.Control
                {...register("marginalFedTaxRate")}
                required
                placeholder="Enter Marginal Fed Tax Rate"
              />
            </Form.Group> */}
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="percentagePropertyOwnerPurchases"
            >
              <Form.Label>PPA Term :</Form.Label>
              <Form.Control
                {...register("pPATerm")}
                required
                placeholder="Enter PPA Term"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="solarDegradation"
            >
              <Form.Label>Percentage Property Owner Purchases % :</Form.Label>
              <Form.Control
                {...register("percentagePropertyOwnerPurchases")}
                required
                placeholder="Enter Percentage Property Owner Purchases"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="adminFeeStartingYear"
            >
              <Form.Label>Solar Degradation % :</Form.Label>
              <Form.Control
                {...register("solarDegradation")}
                required
                placeholder="Enter Solar Degradation"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="yrsAdminFeeEscalator"
            >
              <Form.Label>Yrs Admin Fee Escalator :</Form.Label>
              <Form.Control
                {...register("yrsAdminFeeEscalator")}
                required
                placeholder="Enter Yrs Admin Fee Escalator"
              />
            </Form.Group>

            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="lossLimitation"
            >
              <Form.Label>Loss Limitation:</Form.Label>
              <Form.Control
                {...register("lossLimitation")}
                required
                defaultValue={0}
                placeholder="Loss Limitation"
              />
            </Form.Group>

            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="factorPercentForInput"
            >
              <Form.Label>Factor % For Input :</Form.Label>
              <Form.Control
                {...register("factorPercentForInput")}
                required
                readOnly
                style={{ background: "#DCDCDC" }}
                placeholder="Enter Yrs Admin Fee Escalator"
              />
            </Form.Group>

            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="assumptionInputG4"
            >
              <Form.Label>Desired Contribution :</Form.Label>
              <Form.Control
                {...register("assumptionInputG4")}
                required
                readOnly
                placeholder=""
                style={{ background: "#DCDCDC" }}
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="assumptionInputG4"
            >
              <Form.Label>Equity Contribution :</Form.Label>
              <Form.Control
                {...register("equityContribution")}
                required
                placeholder="Enter your Equity contribution"
              />
            </Form.Group>
            <Form.Group
              style={inlineStyle}
              as={Col}
              sm="4"
              id="assumptionInputG4"
            >
              <Form.Label>Equality Contribution :</Form.Label>
              <Form.Control
                value={data?.equalityContribution}
                readOnly
                placeholder=""
                style={{ background: "#DCDCDC" }}
              />
            </Form.Group>

            {/* HDM MODEL TABLE FIELDS */}

            <h5 style={{ marginTop: "20px" }}>HDM Model</h5>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>Size kW DC :</Form.Label>
              <Form.Control
                {...register("sizeKWDc")}
                required
                placeholder="Enter size kW DC"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>Turnkey $/W DC :</Form.Label>
              <Form.Control
                {...register("turnkeyDC")}
                required
                placeholder="Enter turnkey $/W DC"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>PPA % of Turnkey :</Form.Label>
              <Form.Control
                {...register("ppaPercentOfTurnkey")}
                required
                placeholder="Enter PPA % of turnkey"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>PPA % of Bill of Sale :</Form.Label>
              <Form.Control
                {...register("ppaPercentOfBillOfSale")}
                required
                placeholder="Enter PPA % of bill"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>% of PPA Income Retained :</Form.Label>
              <Form.Control
                {...register("percentOfPPAIncome")}
                required
                placeholder="Enter % of PPA income"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>Fee to customer $/kW DC per year :</Form.Label>
              <Form.Control
                required
                placeholder="Enter fee to customer"
                {...register("feeCustomerDCPerYear")}
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>
                Reserve for expenses in excess of income :
              </Form.Label>
              <Form.Control
                {...register("reverseForExpenseInExcess")}
                required
                placeholder="Enter reserve for expenses"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>Portfolio ave. Yr-1 gen. kWh AC/kW DC :</Form.Label>
              <Form.Control
                required
                placeholder="Enter portfolio ave"
                {...register("portfolioAve")}
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>P&C cost / Total Insured Value / Year :</Form.Label>
              <Form.Control
                {...register("pandcCost")}
                required
                placeholder="Enter P&C cost"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>Wholesale replacement $/W DC :</Form.Label>
              <Form.Control
                {...register("wholesaleReplecement")}
                required
                placeholder="Enter wholesale replacement"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="hdm">
              <Form.Label>Replacement escalator :</Form.Label>
              <Form.Control
                {...register("replecementEscalator")}
                required
                placeholder="Enter replacement escalator"
              />
            </Form.Group>
            <Form.Group
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
              }}
              as={Col}
              sm="4"
              id="submit"
            >
              <Button style={{}} type="Submit">
                Update
              </Button>

              {/* {
                  isLoading ? <Button type="Submit" isLoading={isLoading} > <Preloader show={isLoading} /></Button> :
                    <Button type="Submit" isLoading={isLoading} >Next</Button>
                } */}
              {isLoading
                ? renderLoadingButton()
                : isUserInput
                  ? renderUserInputButton()
                  : null}
              {/* {isUserInput &&
                <Button style={{ marginLeft: '15px' }} onClick={handleClick}>
                  Next
                </Button>} */}
              {error && <span className="text-danger">{error}</span>}
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export const MacrsTable = ({ selectedOption, onUpdate }) => {
  const inlineStyle = { marginTop: "10px" };
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(MacrsSchema),
    mode: "onChange",
    defaultValues: {
      macrsDRYear1: 0,
      macrsDRYear2: 0,
      macrsDRYear3: 0,
      macrsDRYear4: 0,
      macrsDRYear5: 0,
      macrsDRYear6: 0,
    },
  });
  useEffect(() => {
    if (selectedOption && selectedOption?.state) {
      reset(selectedOption);
    }
  }, [selectedOption, reset]);

  const onSubmit = async (values) => {
    const resp = await sendStatesData(selectedOption?._id, values);
    if (!resp.success) {
      onUpdate();
      setError(resp.error);
    }
    showNotification("success", resp?.data?.message);
  };
  return (
    <Card border="light" className="flex-column shadow-sm mb-4">
      <Card.Body className="flex-column flex-sm-row">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group style={inlineStyle} as={Col} sm="4" id="macrsDRYear1">
              <Form.Label>Year 1</Form.Label>
              <Form.Control
                {...register("macrsDRYear1")}
                required
                placeholder="Enter Year 1"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="macrsDRYear2">
              <Form.Label>Year 2</Form.Label>
              <Form.Control
                {...register("macrsDRYear2")}
                required
                placeholder="Enter Year 2"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="macrsDRYear3">
              <Form.Label>Year 3</Form.Label>
              <Form.Control
                {...register("macrsDRYear3")}
                required
                placeholder="Enter Year 3"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="macrsDRYear4">
              <Form.Label>Year 4</Form.Label>
              <Form.Control
                {...register("macrsDRYear4")}
                required
                placeholder="Enter Year 4"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="macrsDRYear5">
              <Form.Label>Year 5</Form.Label>
              <Form.Control
                {...register("macrsDRYear5")}
                required
                placeholder="Enter Year 5"
              />
            </Form.Group>

            <Form.Group style={inlineStyle} as={Col} sm="4" id="macrsDRYear6">
              <Form.Label>Year 6</Form.Label>
              <Form.Control
                {...register("macrsDRYear6")}
                required
                placeholder="Enter Year 6"
              />
            </Form.Group>
            {/* SUBMIT BUTTON */}

            <Form.Group
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
              }}
              as={Col}
              sm="4"
              id="submit"
            >
              <Button style={{}} type="Submit">
                Update
              </Button>
              {error && <span className="text-danger">{error}</span>}
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
export const StateMatrixTable = ({
  states = [],
  setSelectedOption,
  selectedOption,
  onUpdate,
}) => {
  const inlineStyle = { marginTop: "10px", "justify-content": "center" };
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(stateSchema),
    mode: "onChange",
    defaultValues: {
      state: "",
      stateTaxRatePercent: 0,
      stateTaxRatePercentYrs7_20: 0,
      statePTEPaymentPercent: 0,
      stateBonusDepreciation: "",
      stateDepreciation: "",
      stateRevenueSpread: ""
    },
  });


  const stateArray = states?.map((item) => {
    return {
      label: item.state,
      value: item._id,
      ...item,
    };
  });
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setValue("state", selectedOption._id);
    const {
      state,
      stateTaxRatePercent,
      stateTaxRatePercentYrs7_20,
      statePTEPaymentPercent,
      stateBonusDepreciation,
      stateDepreciation,
      stateRevenueSpread,
    } = selectedOption;
    reset({
      state,
      stateTaxRatePercent,
      stateTaxRatePercentYrs7_20,
      statePTEPaymentPercent,
      stateBonusDepreciation,
      stateDepreciation,
      stateRevenueSpread
    });
  };
  useEffect(() => {
    if (stateArray.length > 0) {
      reset({
        ...stateArray[0],
      });
      setSelectedOption(stateArray[0]);
    }
  }, [stateArray?.length, setSelectedOption, reset]);
  console.log(stateArray)

  const onSubmit = async (values) => {
    const resp = await sendStatesData(selectedOption?._id, values);
    if (!resp.success) {
      onUpdate();
      setError(resp.error);
    }
    showNotification("success", resp?.data?.message);
  };
  const StateDropdown = () => {
    return (
      <Select
        {...register("state")}
        value={selectedOption}
        onChange={handleChange}
        options={stateArray}
        placeholder="Please select state..."
      />
    );
  };
  return (
    <Card border="light">
      <Card.Body>
        <Form style={inlineStyle} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Form.Group style={inlineStyle} as={Row} sm="4" id="states">
              <Form.Label sm="2">Select States :</Form.Label>
              <StateDropdown />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              style={inlineStyle}
              as={Row}
              sm="4"
              id="stateTaxRatePercent"
            >
              <Form.Label sm="2">State Tax Rate % :</Form.Label>
              <Col sm="3">
                <Form.Control
                  required
                  placeholder="Enter State Tax Rate %"
                  defaultValue={selectedOption?.stateTaxRatePercent ?? 0}
                  {...register("stateTaxRatePercent")}
                />
              </Col>
            </Form.Group>

            <Form.Group
              style={inlineStyle}
              as={Row}
              sm="4"
              id="stateTaxRatePercentYrs7_20"
            >
              <Form.Label sm="2">State Tax Rate % Yr 7-20 :</Form.Label>
              <Col sm="3">
                <Form.Control
                  required
                  placeholder="Enter State Tax Rate % Yr 7-20"
                  defaultValue={selectedOption?.stateTaxRatePercentYrs7_20 ?? 0}
                  {...register("stateTaxRatePercentYrs7_20")}
                />
              </Col>
            </Form.Group>

            <Form.Group
              style={inlineStyle}
              as={Row}
              sm="4"
              id="statePTEPaymentPercent"
            >
              <Form.Label sm="2">State PTE Payment % :</Form.Label>
              <Col sm="3">
                <Form.Control
                  required
                  placeholder="State PTE Payment %"
                  defaultValue={selectedOption?.statePTEPaymentPercent ?? 0}
                  {...register("statePTEPaymentPercent")}
                />
              </Col>
            </Form.Group>

            <Form.Group style={inlineStyle} as={Row} sm="4" id="name">
              <Form.Label sm="2">State Bonus Depreciation :</Form.Label>
              <Col sm="3">
                <Form.Select
                  {...register("stateBonusDepreciation")}
                  onChange={(e) =>
                    setValue("stateBonusDepreciation", e.target.value)
                  }
                  value={watch("stateBonusDepreciation")}
                >
                  <option value="">Select</option>
                  <option value={"YES"}>Yes</option>
                  <option value={"NO"}>No</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group style={inlineStyle} as={Row} sm="4" id="name">
              <Form.Label sm="2">State Depreciation :</Form.Label>
              <Col sm="3">
                <Form.Select
                  {...register("stateDepreciation")}
                  onChange={(e) =>
                    setValue("stateDepreciation", e.target.value)
                  }
                  value={watch("stateDepreciation")}
                >
                  <option value="">Select</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group style={inlineStyle} as={Row} sm="4" id="name">
              <Form.Label sm="2">State Revenue Spread :</Form.Label>
              <Col sm="3">
                <Form.Select
                  {...register("stateRevenueSpread")}
                  onChange={(e) =>
                    setValue("stateRevenueSpread", e.target.value)
                  }
                  value={watch("stateRevenueSpread")}
                >
                  <option value="">Select</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px",
              }}
              as={Col}
              sm="4"
              id="submit"
            >

              <Button style={{}} type="Submit">
                Update
              </Button>
              {error && <span className="text-danger">{error}</span>}
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};