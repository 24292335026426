import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    Col,
    Row,
    Card,
    Form,
} from "@themesberg/react-bootstrap";
import { useState } from 'react';
import styles from "./index.module.css"
import { Button } from 'react-bootstrap';
import { getBaselineModelValues, updateBaselineModelValues } from '../../service/api';
import { showNotification } from '../../service/showNotification';

const ProformaBackend = () => {

    const { register, handleSubmit, reset } = useForm()
    const inlineStyle = { marginTop: "10px" };
    const [toggleSwitch, setToggleSwitch] = useState({
        lossLimitation: true,
        federalRevenueSpread: true,
    });

    const onSubmit = async (values) => {
        try {
            const payload = { ...values, ...toggleSwitch };
            const response = await updateBaselineModelValues(payload)
            console.log(response, "response")
            if (response.data.success === true) {
                showNotification("success", "Baseline model values updated successfully")
            }
            else {
                showNotification("error", "Baseline model values not updated")
            }
        }
        catch (err) {
            showNotification("error", "Baseline model values not updated")
        }
    }

    const onButtonToggle = async (field) => {
        const newObj = { ...toggleSwitch, [field]: !toggleSwitch[field] };
        setToggleSwitch(newObj);
    };

    const fetchBaselineValues = async () => {
        const response = await getBaselineModelValues();
        const data = response.data?.data;
        if (Object.keys(data)) {
            reset({
                federalBonusDepreciation: data?.federalBonusDepreciation,
                developerDevFee: data?.developerDevFee,
                itcPercent: data?.itcPercent,
                inceptionDevFeeL: data?.inceptionDevFeeL,
                inceptionDevFeeLM: data?.inceptionDevFeeLM,
                inceptionDevFeeHM: data?.inceptionDevFeeHM,
                inceptionDevFeeH: data?.inceptionDevFeeH,
                minStateTaxRateL: data?.minStateTaxRateL,
                minStateTaxRateLM: data?.minStateTaxRateLM,
                minStateTaxRateHM: data?.minStateTaxRateHM,
                minStateTaxRateH: data?.minStateTaxRateH,
                maxStateTaxRateL: data?.maxStateTaxRateL,
                maxStateTaxRateLM: data?.maxStateTaxRateLM,
                maxStateTaxRateHM: data?.maxStateTaxRateHM,
                maxStateTaxRateH: data?.maxStateTaxRateH,
                ampVantageItcPercent: data?.ampVantageItcPercent,
            })
            setToggleSwitch({
                ...toggleSwitch, lossLimitation: data?.lossLimitation,
                federalRevenueSpread: data?.federalRevenueSpread, lossLimitation: data?.lossLimitation
            })
        }
    }

    useEffect(() => {
        fetchBaselineValues()
    }, [])

    return (
        <Card border="light" className="flex-column shadow-sm mb-4">
            <Card.Body className="flex-column flex-sm-row">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="federalBonusDepreciation"
                        >
                            <Form.Label>Federal Bonus Depreciation %</Form.Label>
                            <Form.Control
                                {...register("federalBonusDepreciation")}
                                required
                                placeholder="Enter Federal Bonus Depreciation %"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="itcPercent"
                        >
                            <Form.Label>ITC %</Form.Label>
                            <Form.Control
                                {...register("itcPercent")}
                                required
                                placeholder="Enter Itc %"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="ampVantageItcPercent"
                        >
                            <Form.Label>AmpVantage ITC %</Form.Label>
                            <Form.Control
                                {...register("ampVantageItcPercent")}
                                required
                                placeholder="Enter AmpVantage ITC %"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="developerDevFee"
                        >
                            <Form.Label>Developer Dev Fee %</Form.Label>
                            <Form.Control
                                {...register("developerDevFee")}
                                required
                                placeholder="Enter Developer Dev Fee %"
                            />
                        </Form.Group>
                    </Row>
                    <h4 className={styles.innerHeading} >Low Scenario</h4>
                    <Row>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="minStateTaxRateL"
                        >
                            <Form.Label>Min State Tax Rate</Form.Label>
                            <Form.Control
                                {...register("minStateTaxRateL")}
                                required
                                placeholder="Enter Min State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="maxStateTaxRateL"
                        >
                            <Form.Label>Max State Tax Rate </Form.Label>
                            <Form.Control
                                {...register("maxStateTaxRateL")}
                                required
                                placeholder="Enter Max State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="inceptionDevFeeL"
                        >
                            <Form.Label>Inception Dev Fee</Form.Label>
                            <Form.Control
                                {...register("inceptionDevFeeL")}
                                required
                                placeholder="Enter Inception Dev Fee"
                            />
                        </Form.Group>
                    </Row>
                    <h4 className={styles.innerHeading} >Low - Mid Scenario</h4>
                    <Row>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="minStateTaxRateLM"
                        >
                            <Form.Label>Min State Tax Rate</Form.Label>
                            <Form.Control
                                {...register("minStateTaxRateLM")}
                                required
                                placeholder="Enter Min State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="maxStateTaxRateLM"
                        >
                            <Form.Label>Max State Tax Rate </Form.Label>
                            <Form.Control
                                {...register("maxStateTaxRateLM")}
                                required
                                placeholder="Enter Max State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="inceptionDevFeeLM"
                        >
                            <Form.Label>Inception Dev Fee</Form.Label>
                            <Form.Control
                                {...register("inceptionDevFeeLM")}
                                required
                                placeholder="Enter Inception Dev Fee"
                            />
                        </Form.Group>
                    </Row>
                    <h4 className={styles.innerHeading} >High - Mid Scenario</h4>
                    <Row>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="minStateTaxRateHM"
                        >
                            <Form.Label>Min State Tax Rate</Form.Label>
                            <Form.Control
                                {...register("minStateTaxRateHM")}
                                required
                                placeholder="Enter Min State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="maxStateTaxRateHM"
                        >
                            <Form.Label>Max State Tax Rate </Form.Label>
                            <Form.Control
                                {...register("maxStateTaxRateHM")}
                                required
                                placeholder="Enter Max State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="inceptionDevFeeHM"
                        >
                            <Form.Label>Inception Dev Fee</Form.Label>
                            <Form.Control
                                {...register("inceptionDevFeeHM")}
                                required
                                placeholder="Enter Inception Dev Fee"
                            />
                        </Form.Group>
                    </Row>
                    <h4 className={styles.innerHeading} >High Scenario</h4>
                    <Row>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="minStateTaxRateH"
                        >
                            <Form.Label>Min State Tax Rate</Form.Label>
                            <Form.Control
                                {...register("minStateTaxRateH")}
                                required
                                placeholder="Enter Min State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="maxStateTaxRateH"
                        >
                            <Form.Label>Max State Tax Rate </Form.Label>
                            <Form.Control
                                {...register("maxStateTaxRateH")}
                                required
                                placeholder="Enter Max State Tax Rate"
                            />
                        </Form.Group>
                        <Form.Group
                            style={inlineStyle}
                            as={Col}
                            sm="4"
                            id="inceptionDevFeeH"
                        >
                            <Form.Label>Inception Dev Fee</Form.Label>
                            <Form.Control
                                {...register("inceptionDevFeeH")}
                                required
                                placeholder="Enter Inception Dev Fee"
                            />
                        </Form.Group>
                    </Row>
                    <div className="toggleContainer">
                        <div className="singleToggle">
                            <label className="labelText">Loss Limitation</label>
                            <div className="custom-switch">
                                <input
                                    type="checkbox"
                                    checked={toggleSwitch.lossLimitation}
                                    id="toggle-switch1"
                                    onClick={() => onButtonToggle("lossLimitation")}
                                />
                                <label htmlFor="toggle-switch1" className="switch-label">
                                    <span className="switch-button"></span>
                                    <span className="switch-on">ON</span>
                                    <span className="switch-off">OFF</span>
                                </label>
                            </div>
                        </div>
                        <div className="singleToggle">
                            <label className="labelText">Federal Revenue Spread</label>
                            <div className="custom-switch">
                                <input
                                    type="checkbox"
                                    checked={toggleSwitch.federalRevenueSpread}
                                    id="toggle-switch2"
                                    onClick={() => onButtonToggle("federalRevenueSpread")}
                                />
                                <label htmlFor="toggle-switch2" className="switch-label">
                                    <span className="switch-button"></span>
                                    <span className="switch-on">ON</span>
                                    <span className="switch-off">OFF</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={styles.btnContainer} >
                        <Button type="Submit">
                            Update
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default ProformaBackend