import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { showNotification } from '../../service/showNotification'
import AssetsTable from '../../components/AssetsTable'
import { uploadAssets } from '../../service/api'
import { ButtonLoader } from '../../components/ButtonLoader'

const AssetPage = () => {
    const fileInputRef = useRef();
    const [isLoading, setIsLoading] = useState(false)
    const [isNewUploaded, setIsNewUploaded] = useState(false)

    const handleUpload = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true)
            const file = e.target?.files?.[0];

            const data = await uploadAssets(file);
            console.log(data.data.success);
            if (data.data.success) {
                showNotification("success", "Assets uploaded successfully");
            }
            setIsNewUploaded(!isNewUploaded)
        } catch (error) {
            console.log("error", error.response.data.message);
            showNotification("error", error?.response?.data?.message);
        }
        finally {
            setIsLoading(false)
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block "
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent ",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Assets</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Assets</h4>
                </div>
                <div className="search-container">

                    <Button
                        className="mx-2 mb-2"
                        onClick={() => fileInputRef.current.click()}
                    >
                        {isLoading && <ButtonLoader show={true} />}
                        {!isLoading && "Upload"}
                    </Button>
                    <input
                        onChange={handleUpload}
                        multiple={false}
                        ref={fileInputRef}
                        type="file"
                        hidden
                    />
                </div>
            </div>

            <AssetsTable isNewUploaded={isNewUploaded} />

        </>
    )
}

export default AssetPage