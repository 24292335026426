import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import SubAdmin from "./SubAdmin.jsx";
import Settings from "./Settings";
import BootstrapTables from "./tables/Tables.js";
import Signin from "./Signin.jsx";
import ProfileSetup from "./auth/ProfileSetup.js";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";
import NewReports from "./newReport/NewReports.js";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import ProjectInput from "../components/ProjectInput.js";
import Customization from "../components/Customization.js";
import NDAForm from "../components/NDAForm.js";
import EntityTable from "../components/EntityTable.js";
import { PURLJourney } from "../components/PurlJourney.js";
import { ContactOwner } from "../components/ContactOwner/index.js";
import AddProformaUser from "./proformaUser/AddProformaUser.js";
import AssetPage from "./AssetPage/index.js";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          {rest.location?.pathname === "/new-reports/edit" ? (
            <Preloader show={loaded ? false : true} />
          ) : (
            <></>
          )}
          <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar />
          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

export default () => (
  // const token = localStorage.getItem("token");
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader
      exact
      path={Routes.ProfileSetup.path}
      component={ProfileSetup}
    />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />
    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteWithLoader
      exact
      path={Routes.Customization.path}
      component={Customization}
    />
    <RouteWithSidebar exact path={Routes.SubAdmin.path} component={SubAdmin} />
    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar
      exact
      path={Routes.BootstrapTables.path}
      component={BootstrapTables}
    />
    <RouteWithSidebar
      exact
      path={Routes.NewReports.path}
      component={NewReports}
    />
    <RouteWithSidebar
      exact
      path={Routes.EntityTable.path}
      component={EntityTable}
    />
    <RouteWithSidebar
      exact
      path={Routes.PURLJourney.path}
      component={PURLJourney}
    />
    <RouteWithSidebar
      exact
      path={Routes.ContactOwner.path}
      component={ContactOwner}
    />
    <RouteWithLoader
      exact
      path={Routes.ProjectInput.path}
      component={ProjectInput}
    />
    <RouteWithSidebar
      exact
      path={Routes.ProformaUsers.path}
      component={AddProformaUser}
    />
    <RouteWithSidebar
      exact
      path={Routes.Assets.path}
      component={AssetPage}
    />
    <RouteWithLoader exact path={Routes.NDAForm.path} component={NDAForm} />
    {localStorage.getItem("token") ? (
      <Redirect to={Routes.DashboardOverview.path} />
    ) : (
      <Redirect to={Routes.Signin.path} />
    )}
    {/* <Redirect to={Routes.Signin.path} /> */}
  </Switch>
);
