import React from "react";
import { Spinner } from "react-bootstrap";
import '../assets/Styling/customstyle.css'

export const ButtonLoader = (props) => {
    const { show } = props;

    return (

        <div className={`buttonloader ${show ? "" : "show"}`}>
            {show ? (
                <Spinner animation="border" className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : null}
        </div>


    );
}; 