export const Routes = {
  // pages
  Presentation: { path: "/dashboard/overview" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/reports" },
  SubAdmin: { path: "/users" },
  Settings: { path: "/settings" },
  BootstrapTables: { path: "/tables/constant-tables" },
  NewReports: { path: "/new-reports" },
  Billing: { path: "/page/billing" },
  Invoice: { path: "/page/invoice" },
  Signin: { path: "/sign-in" },
  ForgotPassword: { path: "/auth/forgot-password" },
  ResetPassword: { path: "/auth/password-reset/:token" },
  ProfileSetup: { path: "/auth/profile-setup/:token" },
  NotFound: { path: "/auth/404" },
  ServerError: { path: "/auth/500" },
  ReportHistory: { path: "./components/ReportHistory.js" },
  ReportResult: { path: "/components/ReportHistory" },
  ReportHistoryEdit: { path: "/new-reports/edit" },
  ProjectInput: { path: "/project-input" },
  Customization: { path: '/customization' },
  NDAForm: { path: '/nda-form-Customization' },
  EntityTable: { path: '/entity-info' },
  ProformaUsers: { path: '/progorma-users' },
  PURLJourney: { path: "/purl-journey" },
  ContactOwner: { path: "/contact-owner" },
  Assets: { path: "/assets" },
};
