import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import {
  deleteContactGroup,
  deleteProformaUser,
  updateContactGroup,
} from "../../service/api";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import styles from "./index.module.css";
import { useForm } from "react-hook-form";
import Select from "react-select";
import styles2 from "../../pages/proformaUser/addProforma.module.css";
import { IoIosClose } from "react-icons/io";
import { showNotification } from "../../service/showNotification";

const ContactGroupTable = ({ data, fetchContactGroup, getUsers }) => {
  const { register, reset, handleSubmit } = useForm();
  const [userData, setUserData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const scenerioOptions = [
    {
      label: "1%",
      value: "1",
    },
    {
      label: "2%",
      value: "2",
    },
    {
      label: "3%",
      value: "3",
    },
    {
      label: "4%",
      value: "4",
    },
    {
      label: "5%",
      value: "5",
    },
  ];

  const [selectedLowScenerioOptions, setSelectedLowScenerioOptions] = useState(
    scenerioOptions[0]
  );
  const [selectedLowMidScenerioOptions, setSelectedLowMidScenerioOptions] =
    useState(scenerioOptions[0]);
  const [selectedHighMidScenerioOptions, setSelectedHighMidScenerioOptions] =
    useState(scenerioOptions[0]);
  const [selectedHighScenerioOptions, setSelectedHighScenerioOptions] =
    useState(scenerioOptions[0]);
  const [editItemId, setEditItemId] = useState("");

  const setUser = async (item) => {
    setIsEdit(true);
    setEditItemId(item._id);
    reset({
      developerDevFee: item?.developerDevFee,
      itcPercent: item?.itcPercent,
      folderId: item?.folderId,
      contactGroupName: item.contactGroupName,
    });
    setSelectedLowScenerioOptions({
      value: item.lowScenerio,
      label: item.lowScenerio + "%",
    });
    setSelectedLowMidScenerioOptions({
      value: item.lowMidScenerio,
      label: item.lowMidScenerio + "%",
    });
    setSelectedHighMidScenerioOptions({
      value: item.highMidScenerio,
      label: item.highMidScenerio + "%",
    });
    setSelectedHighScenerioOptions({
      value: item.highScenerio,
      label: item.highScenerio + "%",
    });
  };

  const deleteUser = async (id) => {
    try {
      const response = await deleteContactGroup(id);
      fetchContactGroup();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    console.log(values);
    try {
      const payload = {
        _id: editItemId,
        contactGroupName: values.contactGroupName,
        folderId: values.folderId,
        developerDevFee: values.developerDevFee,
        itcPercent: values.itcPercent,
        lowScenerio: selectedLowScenerioOptions.value,
        lowMidScenerio: selectedLowMidScenerioOptions.value,
        highMidScenerio: selectedHighMidScenerioOptions.value,
        highScenerio: selectedHighScenerioOptions.value,
      };

      console.log(payload, "payload");
      const response = await updateContactGroup(payload);
      if (response.data.success) {
        setIsEdit(false);
        fetchContactGroup();
        getUsers();
        showNotification("success", response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data.length > 0) {
      setUserData(data);
    }
  }, [data]);

  return (
    <>
      <div className="scrollable-table-container2">
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th>Contact Group Name</th>
                  <th>Low Scenerio %</th>
                  <th>Low-Mid Scenerio %</th>
                  <th>High-Mid Scenerio %</th>
                  <th>High Scenerio %</th>
                  <th>Developer Dev Fee %</th>
                  <th>ITC %</th>
                  <th>FolderId</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userData?.length <= 0 ? (
                  <tr>
                    <td className="text-center bg-grey" colSpan={100}>
                      No Records Found.
                    </td>
                  </tr>
                ) : (
                  userData.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item.contactGroupName}</td>
                        <td>
                          {item?.lowScenerio ? `${item?.lowScenerio}%` : "-"}
                        </td>
                        <td>
                          {item?.lowMidScenerio
                            ? `${item?.lowMidScenerio}%`
                            : "-"}
                        </td>
                        <td>
                          {item?.highMidScenerio
                            ? `${item?.highMidScenerio}%`
                            : "-"}
                        </td>
                        <td>
                          {item?.highScenerio ? `${item?.highScenerio}%` : "-"}
                        </td>
                        <td>{item?.developerDevFee ?? "-"}</td>
                        <td>{item?.itcPercent ?? "-"}</td>
                        <td>{item?.folderId}</td>
                        <td>
                          <span>
                            <FaEdit
                              className={styles.editButton}
                              onClick={() => setUser(item)}
                            />
                          </span>
                          <span>
                            <MdDelete
                              className={styles.deleteButton}
                              onClick={() => deleteUser(item._id)}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      {isEdit && (
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <p className={styles.closeBtn} onClick={() => setIsEdit(false)}>
              <IoIosClose />
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={styles.outerFormContainer}
            >
              <p className={styles2.heading}>Update User</p>
              <div className={styles2.formContainer}>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>
                    Contact Group Name
                  </label>
                  <input
                    disabled
                    {...register("contactGroupName")}
                    className={styles2.inputBox}
                    placeholder="Enter Contact Group name"
                    type="text"
                  />
                </div>

                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>
                    Developer Dev Fee %
                  </label>
                  <input
                    {...register("developerDevFee")}
                    className={styles2.inputBox}
                    placeholder="Enter Developer Dev Fee %"
                    type="text"
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}> ITC %</label>
                  <input
                    {...register("itcPercent")}
                    className={styles2.inputBox}
                    placeholder="Enter ITC %"
                    type="text"
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>
                    Destination Folder ID
                  </label>
                  <input
                    {...register("folderId")}
                    className={styles2.inputBox}
                    placeholder="Enter folder ID"
                    type="text"
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>Low Scenerio %</label>
                  <Select
                    options={scenerioOptions}
                    value={{
                      value: selectedLowScenerioOptions.value,
                      label: selectedLowScenerioOptions.label,
                    }}
                    onChange={(selected) =>
                      setSelectedLowScenerioOptions(selected)
                    }
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>
                    Low-Mid Scenerio %
                  </label>
                  <Select
                    options={scenerioOptions}
                    value={{
                      value: selectedLowMidScenerioOptions.value,
                      label: selectedLowMidScenerioOptions.label,
                    }}
                    onChange={(selected) =>
                      setSelectedLowMidScenerioOptions(selected)
                    }
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>
                    High-Mid Scenerio %
                  </label>
                  <Select
                    options={scenerioOptions}
                    value={{
                      value: selectedHighMidScenerioOptions.value,
                      label: selectedHighMidScenerioOptions.label,
                    }}
                    onChange={(selected) =>
                      setSelectedHighMidScenerioOptions(selected)
                    }
                  />
                </div>
                <div className={styles2.inputContainer}>
                  <label className={styles2.labelStyle}>High Scenerio %</label>
                  <Select
                    options={scenerioOptions}
                    value={{
                      value: selectedHighScenerioOptions.value,
                      label: selectedHighScenerioOptions.label,
                    }}
                    onChange={(selected) =>
                      setSelectedHighScenerioOptions(selected)
                    }
                  />
                </div>
              </div>
              <input className={styles2.submitButton} type="submit" />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactGroupTable;
