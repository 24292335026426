import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "../assets/Styling/customstyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { SketchPicker } from "react-color";
import { Col, Row, Form, Breadcrumb, Button } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { CustomizationData } from "../service/api";
import { baseUrl } from "../service/http";

const API_URL = `${baseUrl}`

const Customization = () => {
    const [showSidebar, setShowSidebar] = useState(true);
    const [fontSize, setFontSize] = useState(16);
    const [fontFamily, setFontFamily] = useState("Arial");
    const [fontWeight, setFontWeight] = useState("normal");
    const [fontColor, setFontColor] = useState("#000000");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await CustomizationData();
            const data = await response.json();
            setFontSize(data.fontSize);
            setFontFamily(data.fontFamily);
            setFontWeight(data.fontWeight);
            setFontColor(data.fontColor);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const updateData = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(API_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ fontSize, fontFamily, fontWeight, fontColor }),
            });
            await response.json();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };


    const handleFontSizeChange = (e) => {
        setFontSize(e.target.value);
    };

    const handleFontFamilyChange = (e) => {
        setFontFamily(e.target.value);
    };

    const handleFontWeightChange = (selectedOption) => {
        setFontWeight(selectedOption.value);
    };


    const handleFontColorChange = (color) => {
        setFontColor(color.hex);
    };


    const handleApplyChanges = async () => {
        await updateData();
        applyChangesToUserPanel();
    };


    const handleReset = async () => {
        setFontSize(16);
        setFontFamily("Arial");
        setFontWeight("normal");
        setFontColor("#000000");
        await updateData();
        applyChangesToUserPanel();
    };


    const applyChangesToUserPanel = () => {
        const userPanel = document.getElementById("userPanel");
        if (userPanel) {
            userPanel.style.fontSize = `${fontSize}px`;
            userPanel.style.fontFamily = fontFamily;
            userPanel.style.fontWeight = fontWeight;
            userPanel.style.color = fontColor;
        }
    };


    const handleResize = () => {
        if (window.innerWidth <= 763) {
            setShowSidebar(true);
        } else {
            setShowSidebar(true);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const fontWeightOptions = [
        { value: "normal", label: "Normal" },
        { value: "bold", label: "Bold" },
        { value: "lighter", label: "Lighter" },
        { value: "100", label: "100" },
        // Add other font weights as needed
    ];

    return (
        <>
            {showSidebar && <Sidebar />}
            <div className={showSidebar ? "component" : "component-full-width"}>
                <div className="mx-3">
                    <Navbar />
                </div>

                <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
                    <div className="d-block mb-4 mb-xl-0">
                        <Breadcrumb
                            className="d-none d-md-inline-block "
                            listProps={{
                                className: "breadcrumb-dark breadcrumb-transparent ",
                            }}
                        >
                            <Breadcrumb.Item>
                                <FontAwesomeIcon icon={faHome} />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Customization Section</Breadcrumb.Item>
                        </Breadcrumb>
                        <h4>Customization Section</h4>
                    </div>
                </div>


                <div className="scrollable-table-container2 mx-3 container-fluid">
                    <div className="row">
                        {/* Customization Form */}
                        <div className="customization-form col-md-6 ">
                            <h4>Customization Options</h4>
                            <Form>
                                <Form.Group className='customization-feild' as={Row} controlId="formFontSize">
                                    <Form.Label column sm="4">
                                        Font Size
                                    </Form.Label>
                                    <Col sm="8">
                                        <Row className="align-items-center">
                                            <Form.Control
                                                type="number"
                                                value={fontSize}
                                                onChange={handleFontSizeChange}
                                            />
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group className='customization-feild' as={Row} controlId="formFontFamaily">
                                    <Form.Label column sm="4">
                                        Font Family
                                    </Form.Label>
                                    <Col sm="8">
                                        <Row className="align-items-center">
                                            <Form.Control
                                                type="string"
                                                value={fontFamily}
                                                onChange={handleFontFamilyChange}
                                            />
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <Form.Group className='customization-feild' as={Row} controlId="formFontWeight">
                                    <Form.Label column sm="4">
                                        Font Weight
                                    </Form.Label>
                                    <Col sm="8">
                                        {/* Adjust the width of the Select component */}
                                        <Select
                                            options={fontWeightOptions}
                                            value={{ value: fontWeight, label: fontWeight }}
                                            onChange={handleFontWeightChange}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '100%' // Set width to 100% to match other fields
                                                })
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className='customization-feild' as={Row} controlId="formFontColor">
                                    <Form.Label column sm="4">
                                        Font Color
                                    </Form.Label>
                                    <Col sm="8">
                                        <Row className="align-items-center">
                                            <SketchPicker width="19rem" color={fontColor} onChange={handleFontColorChange} />
                                        </Row>
                                    </Col>
                                </Form.Group>
                                <div className="customization-feild">
                                    <Button className="mx-2" variant="primary" onClick={handleApplyChanges}>Apply Changes</Button>{' '}
                                    <Button variant="primary" onClick={handleReset}>Reset</Button>
                                </div>
                            </Form>
                        </div>
                        {/* Live Preview Section */}
                        <div className="live-preview col-md-6 mb-4">
                            <h4>Live Preview</h4>
                            <div id="userPanel" className="preview-text" style={{ fontSize: `${fontSize}px`, fontFamily, fontWeight, color: fontColor }}>
                                Sample Text in User Panel
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer">
                    <Footer />
                </div>
            </div >
        </>
    );
};
export default Customization;